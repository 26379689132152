<template>
	<div id="app">
		<div>
			<router-view/>
			<app-footer></app-footer>
		</div>
	</div>
</template>

<script>
	import appFooter from '@/components/footer.vue';
	export default {
		name: 'App',
		data() {
			return {

			};
		},
		components:{
			appFooter
		},
		watch: {

		},
		created () {
			
		},
		mounted() {
			
		}
	}
</script>

<style>
	#app {
		min-width: 18.75rem;
		min-height: 100vh;
		/* min-height: calc(100vh - 84px); */
		
	}
</style>